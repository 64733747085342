import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import { createMuiTheme } from "@material-ui/core"
import { Box, Grid, Toolbar, Typography, Container, Button, Card, AppBar } from "@material-ui/core"
import MuiLink from "@material-ui/core/Link"
import TwitterIcon from "@material-ui/icons/Twitter"
import InstagramIcon from "@material-ui/icons/Instagram"
import YouTubeIcon from "@material-ui/icons/YouTube"
import { Link } from "gatsby"
import SEO from "../components/seo"
import "typeface-noto-sans"

import Mask from "../images/about/dtv-mask.png"
import Dtv from "../images/about/logo-dtv.png"
import Ct from "../images/about/ct.png"
import Ready from "../images/about/ready.png"
import Soon from "../images/about/soon.png"
import SaveTime from "../images/about/save-time.png"
import Watch from "../images/about/4k-video.png"
import DtvAce from "../images/about/dtv-ace.png"
import Timeline from "../images/about/timeline.png"
import MoreGames from "../images/about/more-games.png"
import Anyone from "../images/about/anyone.png"
import TextCt from "../images/about/ct-text.png"
import Discord from "../images/about/discord.png"
import Twitter from "../images/about/twitter.png"
import Instagram from "../images/about/instagram.png"
import Youtube from "../images/about/youtube.png"
import DiscordWhite from "../images/about/discord-white.png"
import NaviVsFuria from "../images/about/s1mple-vs-furia.png"
import VitalityVsFaze from "../images/about/zywoo-vs-faze.jpg"

const theme = createMuiTheme()
const styles = makeStyles(() => ({
  navPosition: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  colorNavBackground: {
    backgroundColor: "#1A1F22",
  },
  linkDemtovideo: {
    borderBottom: "solid 3px #4C87FF",
    textDecoration: "none",
    color: "#FFFFFF",
    "&:hover": {
      color: "#FFFFFF",
      textDecoration: "none",
    },
  },
  backgroundAboutDtv: {
    backgroundColor: "#1A1F22",
  },
  socialNetwork: {
    "&:hover": {
      textDecoration: "none",
      color: "#FFFFFF",
    },
  },
  breakReady: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  breakDtvace: {
    [theme.breakpoints.down("sm")]: {
      height: "40%",
    },
  },
  breakSoon: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  breakImgSoon: {
    [theme.breakpoints.down("sm")]: {
      height: "40%",
    },
  },
  breakTextSoon: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "40px",
    },
  },
  breakMarginButton: {
    [theme.breakpoints.down("xl")]: {
      marginBottom: "2em",
    },
  },
  breakMarginFooter: {
    [theme.breakpoints.down("lg")]: {
      marginTop: "20px",
    },
  },
  breakTextSize: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "180px",
      width: "290px",
    },
  },
  breakFontWidth: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "25px",
      fontSize: "34px",
    },
  },
}))

export default function newAbout() {
  const classes = styles()

  return (
    <ThemeProvider theme={theme}>
      <SEO title="about" />
      <Box style={{ overflow: "hidden", backgroundColor: "#0c1116" }}>
        <AppBar position="static" className={classes.colorNavBackground}>
          <Toolbar className={classes.navPosition}>
            <img src={Dtv} width="55px" height="27px" />
            <Link to="/app" color="inherit" target="blank" className={classes.linkDemtovideo}>About demtovideo</Link>
          </Toolbar>
        </AppBar>
        <Grid container justify="center">
          <Grid item xs={12} sm={6} xl={8} lg={8} alignItems="center" container>
            <Container maxWidth="xl">
              <Box ml={5}>
                <Grid container alignItems="center" spacing={0}>
                  <Grid item xs={12} xl={12} lg={12}>
                    <Typography variant="h4" style={{ color: "#FFFFFF" }}>Bored of wasting countless hours training on CS:GO, for zero improvments ?</Typography>
                  </Grid>
                  <Grid item xs={10} sm={6} md={6} xl={2} lg={3}>
                    <Typography variant="h2" style={{ color: "#FFFFFF", fontWeight: "600" }}>Watch</Typography>
                  </Grid>
                  <Box mt={2}>
                    <Grid item xs={10} sm={6} md={6} xl={2} lg={1}>
                      <Typography variant="h5" style={{ color: "#1BE483", letterSpacing: "0.1em" }} >DEMTOVIDEO.TV</Typography>
                    </Grid>
                  </Box>
                  <Grid item xs={10} xl={12} lg={12}>
                    <Typography variant="h2" style={{ color: "#FFFFFF", fontWeight: "600" }}>and unleash your skills !</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Grid>
          <Grid item xs={12} sm={6} xl={4} lg={4} align="right" >
            <img src={Ct} width="100%" height="100%" />
          </Grid>
        </Grid>
        <Box className={classes.backgroundAboutDtv} display="flex" justifyContent="center" pb={10}>
          <Container maxWidth="lg">
            <Box mb={2} display="flex" justifyContent="center">
              <Box mt={-10.7} clone>
                <img src={Mask} />
              </Box>
            </Box>
            <Grid container>
              <Grid item lg={5} xl={5} xs={12} sm={12} align="center">
                <Typography variant="h6" style={{ color: "#1BE483", lineHeight: 8, fontWeight: "600" }} gutterBottom>ABOUT US</Typography>
                <Typography style={{ color: "#FFFFFF", lineHeight: 3 }} gutterBottom>Bored to wait for actions in pro gamers replays, we developed codes to watch a whole game within 15~20 minutes without losing a crumb of the action.</Typography>
              </Grid>
              <Grid item lg={3} xl={3} />
              <Grid item lg={4} xl={4} xs={12} sm={12} align="center">
                <Typography variant="h6" style={{ color: "#1BE483", lineHeight: 8, fontWeight: "600" }} gutterBottom>WHY DTV</Typography>
                <Typography style={{ color: "#FFFFFF", lineHeight: 3 }}>Whether you are striving to become a pro or just like to watch a smooth and enjoyable game,learn CS:GO moves in a record time.</Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container maxWidth="xl">
          <Box my={5}>
            <Typography variant="h3" style={{ color: "#FFFFFF", fontWeight: "600" }}>OUR FEATURES</Typography>
          </Box>
          <Box className={classes.breakReady} clone>
            <img src={Ready} />
          </Box>
          <Box mt={-9}>
            <Grid container align="center" spacing={5}>
              <Grid item lg={4} xs={12} sm={12}>
                <Typography variant="h5" style={{ color: "#FFFFFF", fontWeight: "600" }} gutterBottom>Save Time</Typography>
                <Typography style={{ color: "#FFFFFF", fontWeight: "600" }} gutterBottom>Regular vs. DTV videos</Typography>
                <img src={SaveTime} height="46%" />
                <Box style={{ width: "350px" }} mt={5}>
                  <Typography style={{ color: "#FFFFFF", lineHeight: "32px" }}>By advanced coding application, all the unnecessary contents were automatically removed from the replays.</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} xs={12} sm={12}>
                <Typography variant="h5" style={{ color: "#FFFFFF", fontWeight: "600" }} gutterBottom>Watch in 12K</Typography>
                <Typography style={{ color: "#FFFFFF", fontWeight: "600" }} gutterBottom>From Auto to 2160p60</Typography>
                <img src={Watch} height="46%" />
                <Box style={{ width: "350px" }} mt={5}>
                  <Typography style={{ color: "#FFFFFF" }}>To keep you away from bad quality u.u</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} xs={12} sm={12}>
                <Typography variant="h5" style={{ color: "#FFFFFF", fontWeight: "600" }} gutterBottom>DTV Ace</Typography>
                <Typography style={{ color: "#FFFFFF", fontWeight: "600" }} >All ace on one page</Typography>
                <Box className={classes.breakDtvace} clone>
                  <img src={DtvAce} height="46%" />
                </Box>
                <Box style={{ width: "350px" }} mt={5}>
                  <Typography style={{ color: "#FFFFFF", lineHeight: "32px" }}>A section with aces only, to nourish your satisfaction, your motivation or for the simple pleasure of the eyes.</Typography>
                </Box>
              </Grid>
            </Grid>
            <Box my={10} align="center">
              <Button component={Link} to="/app" size="large" style={{ color: "#000000", backgroundColor: "#FFD33D", borderRadius: "10px", fontWeight: "600" }}>Explore DTV Ace</Button>
            </Box>
          </Box>
        </Container>
        <Container maxWidth="xl">
          <Box>
            <Box className={classes.breakSoon} clone>
              <img src={Soon} />
            </Box>
          </Box>
          <Box mt={-8} ml={2}>
            <Grid container align="center" spacing={5}>
              <Grid item lg={4} xs={12} sm={12}>
                <Typography variant="h5" style={{ color: "#FFFFFF", fontWeight: "600" }} gutterBottom>Utilities on timeline</Typography>
                <Box className={classes.breakImgSoon} clone>
                  <img src={Timeline} />
                </Box>
                <Box style={{ width: "350px" }} mt={5} className={classes.breakTextSoon}>
                  <Typography style={{ color: "#FFFFFF", lineHeight: "32px" }}>Go to timestamps to quickly find the bests smokes, grenades, molotovs. See before, during, and after the utilities’ throwing.</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} xs={12} sm={12}>
                <Typography variant="h5" style={{ color: "#FFFFFF", fontWeight: "600" }} gutterBottom>More games</Typography>
                <Box className={classes.breakImgSoon} clone>
                  <img src={MoreGames} />
                </Box>
                <Box style={{ width: "350px" }} mt={5} className={classes.breakTextSoon}>
                  <Typography style={{ color: "#FFFFFF", lineHeight: "32px" }}>You like the experience on csgo? You want to have same experience with another popular competitive game? Its on our todo list!</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} xs={12} sm={12}>
                <Typography variant="h5" style={{ color: "#FFFFFF", fontWeight: "600" }} gutterBottom>Replays from anyone</Typography>
                <Box className={classes.breakImgSoon} clone>
                  <img src={Anyone} />
                </Box>
                <Box style={{ width: "350px" }} mt={5} className={classes.breakTextSoon}>
                  <Typography style={{ color: "#FFFFFF", lineHeight: "32px" }}>It’ll be your time to shine! Send us your highlights, and we gather the bests ones to show them off to the community</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box my={10}>
          <Container maxWidth="lg">
            <Card style={{ backgroundColor: "#1BE483", borderRadius: "32px", border: "7px solid #1A1F22" }}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={11} lg={5} md={5} align="left">
                  <Grid container direction="column" spacing={6}>
                    <Grid item xs={12} lg={12} md={12}>
                      <Typography className={classes.breakFontWidth} align="left" variant="h3" style={{ color: "#FFFFFF", fontWeight: "600", textAlign: "center" }} >Join the Community !</Typography>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} align="center">
                      <Box style={{ width: "480px" }}>
                        <Typography className={classes.breakTextSize} variant="h6" align="left" style={{ fontWeight: "bolder" }}>ZywOo just made an incredible match but it is not yet on the platform? No problem. Become a contributor! Join the demtovideo discord and start uploading your own demos.</Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} align="center">
                      <Button href="https://discord.gg/c28znCS" variant="contained" style={{ borderRadius: "15px", color: "#7289DA", fontWeight: "600" }}>
                        <Box mr={1}>
                          <img src={Discord} height="30px" />
                        </Box>
                        JOIN DISCORD
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={4} md={4}>
                  <img src={TextCt} />
                </Grid>
                <Grid item lg={2} md={2} />
              </Grid>
            </Card>
          </Container>
        </Box>
        <Box my={10}>
          <Container maxWidth="lg">
            <Grid container justify="center">
              <Grid item xs={12} lg={6} xl={6} container alignItems="flex-start">
                <Grid container justify="center" spacing={8}>
                  <Grid item xs={12} lg={12} align="center">
                    <Box ml={3} lg={1}>
                      <Typography variant="h4" style={{ color: "#FFFFFF" }}>Or follow us on</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} lg={1}>
                    <MuiLink href="https://twitter.com/demtovideo" target="blank"><img src={Twitter} height="50%" /></MuiLink>
                  </Grid>
                  <Grid item xs={2} lg={1}>
                    <MuiLink href="https://www.instagram.com/demtovideo.tv/" target="blank"><img src={Instagram} height="50%" /></MuiLink>
                  </Grid>
                  <Grid item xs={2}>
                    <MuiLink href="https://www.youtube.com/channel/UC_6hTS5AZeghNy76mhHb7Qg/videos" target="blank"><img src={Youtube} height="50%" /></MuiLink>
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={12} align="center">
                    <Box className={classes.breakMarginButton}>
                      <Button component={Link} to="/app" size="large" style={{ color: "#000000", backgroundColor: "#FFD33D", borderRadius: "10px", fontWeight: "600" }}>Explore DTV</Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} xl={6} align="center">
                <Typography variant="h4" style={{ color: "#1BE483", fontWeight: "600" }} gutterBottom >Latests CS:GO DTvideo release</Typography>
                <Grid container spacing={5}>
                  <Grid item lg={6} xl={6} xs={12}>
                    <Typography style={{ color: "#FFFFFF", fontWeight: "600" }} gutterBottom>NAVI GG.BET vs FURIA</Typography>
                    <Box display="flex" justifyContent="space-around">
                      <Typography style={{ color: "#FFFFFF" }} gutterBottom>de_nuke</Typography>
                      <Typography style={{ color: "#FFFFFF" }} gutterBottom>20/5</Typography>
                      <Typography style={{ color: "#FFFFFF", fontWeight: "600" }} gutterBottom>S1mple</Typography>
                    </Box>
                    <img src={NaviVsFuria} height="170px" />
                  </Grid>
                  <Grid item lg={6} xl={6} xs={12}>
                    <Typography style={{ color: "#FFFFFF", fontWeight: "600" }} gutterBottom>Team Vitality vs FaZe Clan</Typography>
                    <Box display="flex" justifyContent="space-around">
                      <Typography style={{ color: "#FFFFFF" }} gutterBottom>de_dust2</Typography>
                      <Typography style={{ color: "#FFFFFF" }} gutterBottom>19/7</Typography>
                      <Typography style={{ color: "#FFFFFF", fontWeight: "600" }} gutterBottom>ZywOo</Typography>
                    </Box>
                    <img src={VitalityVsFaze} height="170px" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <AppBar position="static" style={{ borderTop: "solid 1px #5680FF", height: "100px" }} className={classes.colorNavBackground}>
          <Box mt={5} className={classes.breakMarginFooter}>
            <Box ml={5}>
              <Grid container direction="row" justify="flex-start" spacing={1}>
                <Grid item xs={12} lg={6}>
                  <Grid container direction="row">
                    <Grid item lg={2} xs={4}>
                      <img src={Dtv} width="55px" height="27px" />
                    </Grid>
                    <Grid item lg={3} xs={8}>
                      <Typography style={{ fontWeight: "lighter", marginTop: "6px" }}>Copyright&#169; 2021 demtovideo</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid container justify="flex-end">
                    <Grid item xs={4} lg={2}>
                      <Typography style={{ fontWeight: "lighter" }}>Follow us</Typography>
                    </Grid>
                    <Grid item xs={2} lg={1}>
                      <MuiLink href="https://twitter.com/demtovideo" color="inherit" target="blank"><TwitterIcon className={classes.socialNetwork} /></MuiLink>
                    </Grid>
                    <Grid item xs={2} lg={1}>
                      <MuiLink href="https://www.instagram.com/demtovideo.tv/" color="inherit" target="blank"><InstagramIcon className={classes.socialNetwork} /></MuiLink>
                    </Grid>
                    <Grid item xs={2} lg={1}>
                      <MuiLink href="https://discord.gg/c28znCS" color="inherit" target="blank"><img src={DiscordWhite} height="20px" /></MuiLink>
                    </Grid>
                    <Grid item xs={2} lg={1}>
                      <MuiLink href="https://www.youtube.com/channel/UC_6hTS5AZeghNy76mhHb7Qg/videos" color="inherit" target="blank"><YouTubeIcon className={classes.socialNetwork} /></MuiLink>
                    </Grid>
                    <Grid item xs={2} lg={1} />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </AppBar>
      </Box>
    </ThemeProvider>
  )
}
